<template>
  <div class="card-goods" :class="{'card-goods--option' : isOptionProduct}">
    <button class="btn-reset btn-add-favorite card-goods__favorite">
      <svg class="icon-svg" width="20" height="20"><use xlink:href="dist/img/sprite.svg#hearth"></use></svg>
    </button>
    <a href="" class="card-goods__img-wrap">
      <img src="@/assets/img/goods-1.png" alt="goods" class="card-goods__img">
    </a>
    <div class="card-goods__body">
      <a href="" class="card-goods__detail" v-if="!isOptionProduct">ПОСУДА</a>
      <h3 class="card-goods__name">
        <a href="" class="card-goods__name-link title-line-cap" v-if="!isOptionProduct">Кастрюля Rondell Mocaccino Professional</a>
        <a href="" class="card-goods__name-link title-line-cap" v-if="isOptionProduct">Белый</a>
      </h3>
      <div class="card-goods__price" v-if="!isOptionProduct">
        1 789 ₴
      </div>
      <button
        class="btn btn--blue btn--add-to-card"
        @click="count = 1"
        v-if="count === 0"
      >
        <svg class="icon-svg" width="19" height="18"><use xlink:href="@/assets/img/sprite.svg#add-to-cart"></use></svg>
        <span>В корзину</span>
      </button>
      <Qty
        v-if="count > 0"
        v-model="count"
      />
    </div>
  </div>
</template>
<script>
import Qty from '~/components/quantity'
export default {
  components: {
    Qty
  },
  setup () {
    const count = 0
    const isOptionProduct = null
    return { count, isOptionProduct }
  }
}
</script>
